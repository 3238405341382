
@each $color, $value in $wizard-color {
    .multisteps-#{$color} {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(0,1fr));
    
        .multisteps-progress-btn {
            background-color: transparent;
            position: relative;
            padding-top: 20px;
            border: none;
            outline: none;
    
            .done-icon {
                top: -23px;
                left: 50%;
                opacity: 0;
                display: block;
                position: relative;
                transform: translateX(-50%);
                color: white;
                z-index: 3;
                width: 24px;
                height: 24px;
                background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16" style="color: white;" ><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg>');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
    
            span {
                left: 50%;
                bottom: 0;
                position: absolute;
                transform: translateX(-50%);
            }
    
            &::before {
                position: absolute;
                top: 0;
                left: 50%;
                display: block;
                width: 16px;
                height: 16px;
                content: "";
                border: 4px solid $white;
                box-sizing: border-box;
                z-index: 3;
                transform: translateX(-50%);
                border-radius: 50%;
                background-color: $gray-500;
            }
    
            &::after {
                position: absolute;
                top: 7px;
                left: calc(-50% - 12px / 2);
                display: block;
                width: 100%;
                height: 2px;
                content: "";
                background-color: $gray-300;
                z-index: 1;
            }
    
            &:first-child::after {
                display: none
            }
    
            &.active::before {
                background-color: $white;
                transform: translateX(-50%) scale(1.2);
                border-color: $value;
            }
            &.active::after, &.done::after {
                background-color: $value;
                border-color: $value;
            }
    
            &.done::before {
                transform: translateX(-50%) scale(1.2);
                border-color: $value;
                background-color: $value;
            }
    
            &.done {
                .done-icon {
                    opacity: 1;
                }
            }
        }
    }
  }
