


.circular-pregress {
    height: $roudn-progres-dimension;
    width: $roudn-progres-dimension;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: "";
        position: absolute;
        height: calc($roudn-progres-dimension - $roudn-progres-line);
        width: calc($roudn-progres-dimension - $roudn-progres-line);
        background-color: $white;
        border-radius: 50%;

    }

    .pregress-value {
        position: relative;
        font-size: $roudn-progres-font;
        font-weight: $roudn-progres-font-w;
    }

    progress {
        display: none;
    }
}

@each $color, $value in $theme-colors {
    .circular-pregress-#{$color} {
        @extend .circular-pregress;
        background: conic-gradient($value  10deg, $roudn-progres-bg 0deg);


    }
}