.graphs {
    font-size: 0.625rem;
}

.pe-pointer {
    cursor: pointer;
}



// SAPCER and POSTIONS
hr {
    margin: $br-height 0;
}

.br-sm, br-s {
    margin: $br-height * 1;
}

.br-md, br-m {
    margin: $br-height * 2;
}

.header-spacer {
    margin-top: $header-spacer;
}

.footer-spacer {
    margin-bottom: $header-spacer;
}

.main-content {
    display: flex;
    justify-content: center;
    width: 100%;
}

.hr-dotted {
    border-style: dotted !important;
}

.icon-center {
    transform: translateY(5px);
}

.iframe-responsive {
    position: relative;
    padding-bottom: 56.25%; /* Rapporto di aspetto 16:9 */
    height: 100%;
    width: 100%;

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
    }
}

.navbar-block {
    opacity: 0.6;
    .navbar-blocker {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

::-webkit-scrollbar-track {
    background: trasparent; 
}

.download-card {
    border-radius: $border-radius;
    border: 1px dashed $secondary;

    padding: 2.6rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


.blur-msg-container {
    display: none;
}

.blur-container {
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    &.blurred {
        .blur-content {
            filter: blur(.6rem);
            user-select: none;
            cursor: unset;
            pointer-events: none;
        }
    
        .blur-msg-container {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            filter: none;
        }
    }
}

.graphs-card {
    font-size: .7rem;
    font-weight: 300;
}


//   @media (max-width: 1000px) {
//     body {
//       filter: blur(10px);
//       pointer-events: none;
//       user-select: none;
//     }
    
//     #content {
//       display: none;
//     }
    
//     .blur-text {
//       display: block;
//     }}


.checkout-widget {


    transition: width .1s ease;

    .widget-btn {
        background-color: $brand;
        height: 3.5rem;
        width: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1000rem;
        cursor: pointer;
        user-select: none;

        position: absolute;
        top: 0;
        right: 0;

    }

    .widget-icon {
        font-size: 2.5rem;
        color: $primary;
    }

    .widget-icon-close {
        font-size: 2.5rem;
        color: $primary;
        display: none;
    }

    .checkout-content {
        opacity: 0;

        margin-top: 1.75rem;
        margin-right: 1.75rem;

        transition: opacity .2s ease;
    }

    // .widget-btn:hover {
    //     background-color: $primary;
    //     border-color: $brand;
    //     .widget-icon {
    //         color: $brand;
    //     }
    // }

    &.expanded {

        .checkout-content {
            opacity: 1;
        }

        .widget-btn {
            background-color: $primary;
            .widget-icon {
                color: $brand;
                display: none;
            }

            .widget-icon-close {
                color: $brand;
                display: block;
            }
        }
    }

    // .checkout-content {
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     width: 25rem;
    // }

}