

.form-pills {
    padding: $form-pills-ring-padding;
    border-radius: $form-pills-ring-redius;
    // border: $form-pills-border-width solid $form-pills-border-color;
    background-color: $form-pills-bg-color;
    display: grid;
    grid-auto-flow: column;
    gap: $form-pills-gap;
    justify-items: stretch;

}
.radio-pills-lable {
    display: inline-block;
    cursor: pointer;

    .radio-button {
      border-radius: $from-pills-btn-border-radius;
      width: 100%;

      display: flex;
      justify-content: center;
      padding: $from-pills-btn-padding-y 0;

      background-color: transparent;
      border: none;
      color: $from-pills-btn-color;
    }
  
    input[type="radio"] {
      display: none;
  
      &:disabled {
          & + .radio-button {
              background-color: $form-card-disabled-bg;
              color: $form-card-disabled-color;
              border-color: $form-card-disabled-border-color;
  
              cursor: default;
          }
      }
  
      &:checked + .radio-button {
          background-color: $from-pills-btn-active-bg ;
          color: $from-pills-btn-active-color ;
          border-color: transparent;
        }
    }
  }
  