.form-password {
    position: relative;
    
    .password-icon {
        position: absolute;
        display: block;
        top: 50%;
        right: $password-icon-margin-right;
        height: $password-icon-dimension;
        width: $password-icon-dimension;
        background-image: escape-svg($password-icon-hide);
        background-repeat: no-repeat;
        background-position: center;
        pointer-events: auto;
        color: $secondary;
        cursor: pointer;
        transform: translateY(-50%);


        &.show {
            background-image: escape-svg($password-icon-show);
        }
      }
}

