@font-face {
  font-family: "Gilroy";
  font-weight: 100;
  src: url("../fonts/gilroy/gilroy_thin.woff2") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 200;
  src: url("../fonts/gilroy/gilroy_light.woff2") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-weight: 300;
  src: url("../fonts/gilroy/gilroy_regular.woff2") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url("../fonts/gilroy/gilroy_medium.woff2") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url("../fonts/gilroy/gilroy_heavy.woff2") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: url("../fonts/gilroy/gilroy_semibold.woff2") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  src: url("../fonts/gilroy/gilroy_bold.woff2") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  src: url("../fonts/gilroy/gilroy_extrabold.woff2") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 900;
  src: url("../fonts/gilroy/gilroy_black.woff2") format("truetype");
}


.icon-fill {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}


@each $size, $value in $icon-font-size {
  .icon-size-#{$size} {
    font-size: $value;
  }
}