// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select-dropdown {
  position: relative;
  overflow: visible;



  &::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 25%;
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"%3E%3Cpath d="M480-362q-8 0-15-2.5t-13-8.5L267-558q-11-11-10.5-27.5T268-613q11-11 28-11t28 11l156 156 157-157q11-11 27.5-10.5T692-613q11 11 11 28t-11 28L508-373q-6 6-13 8.5t-15 2.5Z"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    z-index: 10;
    transition: transform .2s ease;
    cursor: pointer;
    user-select: none;
  }

  .dropdown-input {
    --#{$prefix}form-select-bg-img: #{escape-svg($form-select-indicator)};
  
    display: inline-flex;
    position: relative;
    justify-content: start;
    width: 100%;
    padding: $form-select-padding-y $form-select-indicator-padding
      $form-select-padding-y $form-select-padding-x;
    font-family: $form-select-font-family;
    @include font-size($form-select-font-size);
    font-weight: $form-select-font-weight;
    line-height: $form-select-line-height;
    color: $form-select-color;
    background-color: $white;
    border: $form-select-border-width solid $form-select-border-color;
    @include border-radius($form-select-border-radius, 0);
    @include box-shadow($form-select-box-shadow);
    @include transition($form-select-transition);
    appearance: none;
    z-index: 5;

    cursor: pointer;
    user-select: none ;

    &.filter {
      padding-left: calc($form-select-padding-x + 30px);
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M320-240H160q-17 0-28.5-11.5T120-280q0-17 11.5-28.5T160-320h160q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm480-400H160q-17 0-28.5-11.5T120-680q0-17 11.5-28.5T160-720h640q17 0 28.5 11.5T840-680q0 17-11.5 28.5T800-640ZM560-440H160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520h400q17 0 28.5 11.5T600-480q0 17-11.5 28.5T560-440Z"/></svg>');
      background-repeat: no-repeat;
      background-position: center left;
      background-position-x: $form-select-padding-x;
    }
  
    &:focus {
      border-color: $form-select-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow(
          $form-select-box-shadow,
          $form-select-focus-box-shadow
        );
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $form-select-focus-box-shadow;
      }
    }

    @each $color, $value in $dropdown-select-color {
      &.is-#{$color} {
        border-color: $value;
      }
    } 
  
    &[multiple],
    &[size]:not([size="1"]) {
      padding-right: $form-select-padding-x;
      background-image: none;
    }
  
    &:disabled {
      color: $form-select-disabled-color;
      background-color: $form-select-disabled-bg;
      border-color: $form-select-disabled-border-color;
    }
  
    // Remove outline from select box in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $form-select-color;
    }

    &.dropdown-input-sm {
      padding-top: $form-dropdown-padding-y-sm;
      padding-bottom: $form-dropdown-padding-y-sm;
      padding-left: $form-select-padding-x-sm;
      @include font-size($form-select-font-size-sm);
      @include border-radius($form-select-border-radius-sm);
      
    }

    &.dropdown-input-lg {
      padding-top: $form-select-padding-y-lg;
      padding-bottom: $form-select-padding-y-lg;
      padding-left: $form-select-padding-x-lg;
      @include font-size($form-select-font-size-lg);
      @include border-radius($form-select-border-radius-lg);
    }
    
  }

  .dropdown-option {
    display: none;
    position: absolute;
    width: 100%;
    padding: 0;
    background-color: $white;
    z-index: 500;
    overflow: auto;

    border: $form-select-border-width solid $form-select-focus-border-color;
    border-top: none;
    @include border-bottom-radius($form-select-border-radius);
    @include box-shadow($form-select-box-shadow);

    &.marker {
      li {
        &::before {
          content: "";
          opacity: 0;
          display: inline-block;
          width: 24px;
          height: 24px;
          background-image: url('data:image/svg+xml, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_149_2820" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_149_2820)"><path d="M10 13.6L15.9 7.70005C16.0834 7.51672 16.3167 7.42505 16.6 7.42505C16.8834 7.42505 17.1167 7.51672 17.3 7.70005C17.4834 7.88338 17.575 8.11672 17.575 8.40005C17.575 8.68338 17.4834 8.91672 17.3 9.10005L10.7 15.7C10.5 15.9 10.2667 16 10 16C9.73338 16 9.50005 15.9 9.30005 15.7L6.70005 13.1C6.51672 12.9167 6.42505 12.6834 6.42505 12.4C6.42505 12.1167 6.51672 11.8834 6.70005 11.7C6.88338 11.5167 7.11672 11.425 7.40005 11.425C7.68338 11.425 7.91672 11.5167 8.10005 11.7L10 13.6Z" fill="%23000729"/></g></svg>');
          background-repeat: no-repeat;  
        }
        &.active::before {
          opacity: 1;
        }
      }
    }

    li {
      padding: .5rem .75rem;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: start;

      &:hover {
        background-color: $gray-200;
      }
    }
  }

  &.expanded {
    .dropdown-input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: $form-select-bg;
      padding-bottom: calc($form-select-padding-y);
      border-color: $form-select-focus-border-color;
    }

    .dropdown-option {
      display: block;
    }

    &::before {
      transform: rotate(180deg);

    }
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .form-select-dropdown {
      --#{$prefix}form-select-bg-img: #{escape-svg($form-select-indicator-dark)};
    }
  }
}
