.form-colors {
}
.radio-color-label {
  display: inline-block;
  cursor: pointer;

  height: 3rem;
  width: 3rem;

  border-radius: 50%;

  background-color: transparent;

  border: 0.75rem solid transparent;
  box-shadow: 0px 0px 0px 1px $secondary;

  .radio-button {
    position: relative;
    height: 1.5rem;
    width: 1.5rem;

    padding: 0;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    border-radius: 50%;
  }

  input[type="radio"] {
    display: none;

    &:disabled + .radio-button {
      & {
        border: 0.75rem solid $gray-500;

        height: 3rem;
        width: 3rem;


      }
    }

    &:checked + .radio-button {
      border: 0.75rem solid $white;

      box-shadow: 0px 0px 0px 1px $brand;

      height: 3rem;
      width: 3rem;
    }
  }
}
