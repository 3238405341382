.radio-card-lable {
  display: inline-block;
  cursor: pointer;

  .radio-button {
    display: flex;
    height: $form-card-radio-x;
    width: $form-card-radio-y;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    border: $form-card-border-width solid $form-card-border-color;
    border-radius: $form-card-border-radius;

    user-select: none;

    color: $form-card-color;

    &:hover {
      background-color: tint-color($dark, 90%);
    }

    .radio-icon {
      font-size: $form-card-icon-size;
    }
  }

  input[type="radio"] {
    display: none;

    &:disabled {
        & + .radio-button {
            background-color: $form-card-disabled-bg;
            color: $form-card-disabled-color;
            border-color: $form-card-disabled-border-color;

            cursor: default;
        }
    }

    &:checked + .radio-button {
        background-color: $form-card-selected-bg;
        color: $form-card-selected-color;
        border-color: $form-card-selected-border-color;
      }
  }
}
