// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-search-dropdown {
  position: relative;
  overflow: visible;

  .dropdown-input {
    --#{$prefix}form-select-bg-img: #{escape-svg($form-select-indicator)};
  
    display: inline-flex;
    position: relative;
    justify-content: start;
    width: 100%;
    font-family: $form-select-font-family;
    @include font-size($form-select-font-size);
    font-weight: $form-select-font-weight;
    line-height: $form-select-line-height;
    color: $form-select-color;
    background-color: $white;
    border: $form-select-border-width solid $form-select-border-color;
    @include border-radius($form-select-border-radius, 0);
    @include box-shadow($form-select-box-shadow);
    @include transition($form-select-transition);
    appearance: none;
    z-index: 5;


    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }
  
    &:focus {
      border-color: $form-select-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow(
          $form-select-box-shadow,
          $form-select-focus-box-shadow
        );
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $form-select-focus-box-shadow;
      }
    }
  
    &[multiple],
    &[size]:not([size="1"]) {
      padding-right: $form-select-padding-x;
      background-image: none;
    }
  
    &:disabled {
      color: $form-select-disabled-color;
      background-color: $form-select-disabled-bg;
      border-color: $form-select-disabled-border-color;
    }
  
    // Remove outline from select box in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $form-select-color;
    }

    &.dropdown-input-sm {
      padding-top: $form-dropdown-padding-y-sm;
      padding-bottom: $form-dropdown-padding-y-sm;
      padding-left: $form-select-padding-x-sm;
      @include font-size($form-select-font-size-sm);
      @include border-radius($form-select-border-radius-sm);
    }

    &.dropdown-input-lg {
      padding-top: $form-select-padding-y-lg;
      padding-bottom: $form-select-padding-y-lg;
      padding-left: $form-select-padding-x-lg;
      @include font-size($form-select-font-size-lg);
      @include border-radius($form-select-border-radius-lg);
    }
    
  }

  .dropdown-option {
    display: none;
    position: absolute;
    overflow: auto;
    width: 100%;
    list-style: none;
    padding: 0;
    background-color: $white;
    z-index: 500;

    border: $form-select-border-width solid $form-select-focus-border-color;
    border-top: none;
    @include border-bottom-radius($form-select-border-radius);
    @include box-shadow($form-select-box-shadow);

    box-shadow: 0px 12px 8px tint-color($dark, 90%);
    li {
      padding: .5rem .75rem;
      cursor: pointer;
      
      &:hover {
        background-color: $gray-200;
      }
    }
  }

  &.expanded {
    .dropdown-input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: $form-select-bg;
    }

    .dropdown-option {
      display: block;
    }
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .form-select-dropdown {
      --#{$prefix}form-select-bg-img: #{escape-svg($form-select-indicator-dark)};
    }
  }
}

