@mixin expanded-styles {
  .navbar-brand {
    .nav-logo {
      transform: translateX(0px);
      transition: transform 0.2s ease;
    }

    .nav-title {
      opacity: 1;
      transition: opacity 0.2s ease 0s;
    }
  }

  .navbar-nav {
    overflow-y: auto;
    
    .nav-item {
      .nav-link {
        .nav-link-text {
          opacity: 1;
          transition: opacity 0s ease 0s;
        }
      }
      .nav-accordion {
        .accordion-btn::after {
          opacity: 1;
        }
      }
    }
  }

  .nav-footer {
    .nav-item {
      .nav-link {
        .nav-link-text {
          opacity: 1;
          transition: opacity 0s ease 0s;
        }
      }
    }
  }
}


.aside-content {
  margin-left: $aside-min-w;
  &.expanded {
    margin-left: $aside-max-w;
  }
  transition: margin-left 0.3s ease;
}

.aside-body-margin {
  margin-right: $aside-padding-x;
}

.aside-navbar-container {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: $aside-padding-y $aside-padding-x-end $aside-padding-y
    $aside-padding-x;

  background-color: $body-bg;

  .aside-navbar {
    position: relative;
    width: $aside-collpase-width;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: hidden;

    transition: width 0.22s ease;

    .nav-link {
      align-items: center;
      width: 100%;
      display: flex;
      border-radius: $aside-nav-border-radius;
      padding: $aside-nav-item-padding;
      cursor: pointer;
      white-space: nowrap;

      .nav-link-icon {
        height: $aside-nav-item-icon-dimension;
        width: $aside-nav-item-icon-dimension;
      }

      .nav-link-text {
        opacity: 0;
        margin-left: $aside-nav-item-text-distance;
        text-overflow: ellipsis;
        transition: opacity 0s ease 0.2s;
      }

      &.active {
        background-color: $aside-active-bg;

        &:hover {
          background-color: $aside-active-hover-bg;
        }

        .nav-link {
          color: $aside-color;
        }
      }

      &.disabled {
        color: $nav-link-disabled-color;
      }

      &:hover {
        background-color: $aside-hover-bg;

        .nav-link {
          color: $aside-color;
        }
      }
    }

    .navbar-blocker {
      opacity: 0.5;
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      
      .navbar-blocker-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
      }
    }

    .navbar-brand {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: $aside-brand-margin;
      white-space: nowrap;

      .nav-logo {
        transform: translateX($aside-icon-centry);
        transition: transform 0.2s ease;
      }

      .nav-title {
        margin-left: $aside-nav-item-text-distance;
        opacity: 0;
        transition: opacity 0s ease 0.2s;
      }
    }

    .nav-footer {
      width: 100%;
      list-style: none;
      padding-left: 0;
      margin-top: auto;
      margin-bottom: 0;

      .nav-item {
        margin-bottom: $aside-nav-item-space;
      }
    }

    .nav-hemlp-center {
      position: relative;
      margin-top: auto;
      margin-bottom: 0;
    }


    .navbar-nav {
      width: 100%;
      position: relative;
      overflow-y: hidden;
      overflow-x: hidden;

      .nav-item {
        margin-bottom: $aside-nav-item-space;
        width: 100%;
        position: relative;
        user-select: none;

        .accordion-collapse {
          list-style: none;
          padding: 0;

          li {
            margin-top: $aside-nav-item-space;
          }

          .nav-link {
            padding-left: $aside-accordio-link-padding;
          }
        }

        .nav-accordion {
          .accordion-btn {
            color: $secondary;

            &.active,
            &:hover {
              color: $aside-color;
            }

            &::after {
              content: "";
              position: absolute;
              opacity: 0;
              right: $aside-nav-item-p-x;
              top: 17px;
              width: 12px;
              height: 7px;
              transform: rotate(180deg);
              background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7' fill='%23000729'><path d='M5.99995 0.0250125C6.13328 0.0250125 6.26245 0.0500126 6.38745 0.100013C6.51245 0.150013 6.61662 0.216679 6.69995 0.300013L11.3 4.90001C11.4833 5.08335 11.575 5.31668 11.575 5.60001C11.575 5.88335 11.4833 6.11668 11.3 6.30001C11.1166 6.48335 10.8833 6.57501 10.6 6.57501C10.3166 6.57501 10.0833 6.48335 9.89995 6.30001L5.99995 2.40001L2.09995 6.30001C1.91662 6.48335 1.68328 6.57501 1.39995 6.57501C1.11662 6.57501 0.883285 6.48335 0.699951 6.30001C0.516618 6.11668 0.424951 5.88335 0.424951 5.60001C0.42495 5.31668 0.516618 5.08335 0.699951 4.90001L5.29995 0.300013C5.39995 0.200013 5.50828 0.129179 5.62495 0.0875125C5.74162 0.045846 5.86662 0.0250125 5.99995 0.0250125Z' fill='%23000729'/></svg>");
              transition: transform 0.2s ease, opacity 0s ease 0.1s;
            }
          }

          .nav-accordion-collapse {
            list-style: none;
            overflow: hidden;
            padding: 0;

            transition: max-height 0.2s ease;
            li {
              margin-top: $aside-nav-item-space;
            }

            .nav-link {
              padding-left: $aside-nav-item-text-distance +
                $aside-nav-item-icon-dimension + $aside-nav-item-p-x;
            }
          }

          &.expanded {
            .accordion-btn::after {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
  }

  &.expanded {
    .aside-navbar {
      width: $aside-expanded-width;

      @include expanded-styles;
    }
  }

}

@media (min-width: $aside-nav-brakepoint) {
  .aside-navbar-container {
    .aside-navbar {
      width: $aside-expanded-width;

      @include expanded-styles;
    }
  }
  
  .aside-content {
    margin-left: $aside-max-w !important;
  }
}

// @media (min-width: $aside-nav-brakepoint) {
//   main{
//       width: $aside-nav-brakepoint;
//       position: absolute;
//       left: 50%;
//       transform: translateX(-50%);

//       .aside-navbar-container  {
//         position: sticky;
//         height: 100vh;
//       }
  
//       .aside-content {
//         margin-left: 0 !important;
//       }
//   }
// }